@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .gradiant-card-bg {
        background: linear-gradient(51.47deg, rgba(255, 113, 113, 0.3) 0.59%, rgba(17, 149, 30, 0.072) 42.57%, rgba(36, 83, 205, 0) 48.87%, rgba(1, 8, 26, 0.3) 90.33%);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    input[type="radio"] {
        /* remove standard background appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .triangle-down {
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 24px solid #323242;
        border-radius: 4px;
    }
    .triangle-down-small {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 20px solid #323242;
        border-radius: 4px;
    }
}

@layer components {
    .custom-radio-btn {
        @apply border h-3 w-3 rounded-full border-range-bg checked:bg-dark-yellow cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .custom-checkbox {
        @apply border h-3 w-3 rounded-sm border-range-bg accent-dark-yellow cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .yellow-bg-btn {
        @apply text-sm font-semibold text-extra-dark-gray px-7 py-2 rounded-lg bg-dark-yellow disabled:cursor-default
    }
    .yellow-border-btn {
        @apply text-sm font-semibold text-strong-gray px-7 py-2 rounded-lg bg-transparent border border-dark-yellow disabled:cursor-default
    }
}